import React, { useState } from 'react'
import CohortSelectionContext from './Context'

const CohortSelectionProvider = ({children}) => {
  const [state, setState] = useState({
    selectedCohort: '',
    changeCohort: changeCohort
  })

  function changeCohort (e) {
    const selectedCohort = e.target.value
    setState({ selectedCohort, changeCohort })
  }

  return (
    <CohortSelectionContext.Provider value={state}>
      {children}
    </CohortSelectionContext.Provider>
  )
}

export default CohortSelectionProvider
