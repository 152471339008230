import React, { useContext } from 'react'
import CohortSelectionContext from './Context'

export const CohortSelectionLink = ({repo}) => {
  const { selectedCohort } = useContext(CohortSelectionContext)

  return selectedCohort
    ? <a href={`https://github.com/${selectedCohort}/${repo}`}>{repo}</a>
    : <span>{repo} (select your cohort for a link)</span>
}
