import React, { useState } from 'react'
import NavCollapseContext from './Context'
import config from '../../config';

const NavCollapseProvider = ({children}) => {
  const { collapsedByDefault } = config.sidebar
  let location;
  if(typeof(document) != 'undefined') {
    location = document.location;
  }
  const defaults = collapsedByDefault.reduce((acc, node) => {
    const active =
      location && (location.pathname.includes(node));
    acc[node] = !active
    return acc
  }, {});
  const [collapsed, setCollapsed] = useState(defaults)

  return (
    <NavCollapseContext.Provider value={[collapsed, setCollapsed]}>
      {children}
    </NavCollapseContext.Provider>
  )
}

export default NavCollapseProvider
