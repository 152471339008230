const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://student-handbook.devacademy.life',
    gaTrackingId: null,
  },
  header: {
    logo: '/images/brandmark_white_pattern.png',
    logoLink: 'https://student-handbook.devacademy.life',
    title: 'Dev Academy Student Handbook',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: 'learn-hasura-test',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/index',
      '/foundations',
      '/bootcamp',
      '/policies',
      '/guides',
      '/our-mission',
      '/wellbeing',
      '/faq',
    ],
    "collapsedByDefault": [
      "/foundations",
      "/foundations/course-overview",
      "/foundations/course-prep",
      "/foundations/course-prep/prep-curriculum",
      "/foundations/curriculum",
      "/foundations/curriculum/sprint-1",
      "/foundations/curriculum/sprint-2",
      "/foundations/curriculum/sprint-3",
      "/foundations/curriculum/sprint-4",
      "/foundations/curriculum/sprint-5",
      "/foundations/curriculum/sprint-1/04-tech",
      "/foundations/curriculum/sprint-1/05-core",
      "/foundations/curriculum/sprint-2/01-tech",
      "/foundations/curriculum/sprint-2/02-core",
      "/foundations/curriculum/sprint-3/02-tech",
      "/foundations/curriculum/sprint-3/01-core",
      "/foundations/curriculum/sprint-4/02-tech",
      "/foundations/curriculum/sprint-4/02-tech/t6-js-kata",
      "/foundations/curriculum/sprint-4/01-core",
      "/foundations/curriculum/sprint-5/01-tech",
      "/foundations/curriculum/sprint-5/02-core",
      "/foundations/curriculum/sprint-resources",
      "/foundations/curriculum/stretch",
      "/foundations/resources",
      "/bootcamp",
      "/bootcamp/activities",
      "/bootcamp/learning-objectives",
      "/bootcamp/01-javascript",
      "/bootcamp/02-client-side-rendering",
      "/bootcamp/03-apis-async",
      "/bootcamp/04-express",
      "/bootcamp/05-databases",
      "/bootcamp/06-fullstack",
      "/bootcamp/07-projects",
      "/bootcamp/08-final-projects",
      "/bootcamp/09-bootcamp-end",
      "/ka-tautoro",
      "/policies",
      "/guides"
    ],
    links: [{ text: 'Dev Academy web site', link: 'https://devacademy.co.nz' }],
    frontline: false,
    ignoreIndex: false,
  },
  siteMetadata: {
    title: 'Dev Academy Student Handbook',
    description:
      'Syllabus, technical concepts and important information for students',
    ogImage: null,
    docsLocation:
      'https://github.com/dev-academy-programme/student-handbook/tree/master/content',
    favicon: '/images/brandmark_favicon.png',
  },
}

module.exports = config
