import React, { useContext } from "react";
import { Link as GatsbyLink } from "gatsby";
import isAbsoluteUrl from "is-absolute-url";
import NavCollapseContext from '../navCollapse/Context'

const Link = ({ to, ...props }) => {
  const [collapsed, setCollapsed] = useContext(NavCollapseContext)
  return (
    isAbsoluteUrl(to) ? (
      <a href={to} target="_blank" {...props} />
    ) : (
      <GatsbyLink
        to={to}
        onClick={() => {
          const newCollapsed = Object.keys(collapsed).reduce((acc, url) => {
            acc[url] = to.includes(url) ? false : collapsed[url]
            return acc
          }, {});
          setCollapsed(newCollapsed)}
        }
        {...props} />
    )
  )
}

export default Link;
