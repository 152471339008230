export default [
  {
    id: 'whai-piwakawaka-2024',
    name: 'Whai/Pīwakawaka 2024'
  },
  {
    id: 'kahikatea-2024',
    name: 'Kahikatea 2024'
  },
  {
    id: 'karaka-2024',
    name: 'Karaka 2024'
  },
  {
    id: 'harakeke-2024',
    name: 'Harakeke 2024'
  },
  {
    id: 'tohora-2024',
    name: 'Tohorā 2024'
  },
  {
    id: 'matai-2023',
    name: 'Mataī 2023'
  },
  {
    id: 'mako-2023',
    name: 'Mako 2023'
  },
  {
    id: 'pikopiko-2023',
    name: 'Pikopiko 2023'
  },
  {
    id: 'kahikatea-2023',
    name: 'Kahikatea 2023'
  },
  {
    id: 'piwakawaka-2023',
    name: 'Pīwakawaka 2023'
  },
  {
    id: 'whai-2023',
    name: 'Whai 2023'
  },
  {
    id: 'kahu-2023',
    name: 'Kāhu 2023'
  },
  {
    id: 'harakeke-2023',
    name: 'Harakeke 2023'
  },
  {
    id: 'tohora-2023',
    name: 'Tohorā 2023'
  },
  { id: 'aihe-2022',
    name: 'Aihe 2022'
  },
  { id: 'hihi-2022',
    name: 'Hihi 2022'
  },
  { id: 'horoeka-2022',
    name: 'Horoeka 2022'
  },
  { id: 'Pohutukawa-2022',
    name: 'Pōhutukawa 2022'
  },
  { id: 'roa-2022',
    name: 'Roa 2022'
  },
  { id: 'manaia-2022',
    name: 'Manaia 2022'
  },
  {
    id: 'matai-2022',
    name: 'Matai 2022'
  },
  {
    id: 'kotare-2022',
    name: 'Kōtare 2022'
  },
  {
    id: 'mako-2022',
    name: 'Mako 2022'
  },
  {
    id: 'whai-2022',
    name: 'Whai 2022'
  },
  {
    id: 'kahikatea-2022',
    name: 'Kahikatea 2022'
  },  
  {
    id: 'piwakawaka-2022',
    name: 'Pīwakawaka 2022'
  },
  {
    id: 'kahu2022',
    name: 'Kāhu 2022'
  },
  {
    id: 'harakeke-2022',
    name: 'Harakeke 2022'
  },
  {
    id: 'tohora-2022',
    name: 'Tohorā 2022'
  },
  { id: 'aihe-2021',
    name: 'Aihe 2021'
  },
  { id: 'hihi-2021',
    name: 'Hihi 2021'
  },
  { id: 'horoeka-2021',
    name: 'Horoeka 2021'
  },
  { id: 'Pohutukawa-2021',
    name: 'Pōhutukawa 2021'
  },
  { id: 'roa-2021',
    name: 'Roa 2021'
  },
  { id: 'manaia-2021',
    name: 'Manaia 2021'
  },
  {
    id: 'matai-2021',
    name: 'Matai 2021'
  },
  {
    id: 'kotare-2021',
    name: 'Kōtare 2021'
  },
  {
    id: 'mako-2021',
    name: 'Mako 2021'
  },
  {
    id: 'whai-2021',
    name: 'Whai 2021'
  },
  {
    id: 'kahikatea-2021',
    name: 'Kahikatea 2021'
  },  
  {
    id: 'piwakawaka-2021',
    name: 'Pīwakawaka 2021'
  },
  {
    id: 'kahu-2021',
    name: 'Kahu 2021'
  },
  {
    id: 'harakeke-2021',
    name: 'Harakeke 2021'
  },
  {
    id: 'tohora-2021',
    name: 'Tohorā 2021'
  },
  {
    id: 'aihe-2020',
    name: 'Aihe 2020'
  },
  {
    id: 'hihi-2020',
    name: 'Hihi 2020'
  },
  {
    id: 'horoeka-2020',
    name: 'Horoeka 2020'
  },
  {
    id: 'manaia-2020',
    name: 'Manaia 2020'
  },
  {
    id: 'pohutukawa-2020',
    name: 'Pohutukawa 2020'
  },
  {
    id: 'roa-2020',
    name: 'Roa 2020'
  },
  {
    id: 'kotare-2020',
    name: 'Kōtare 2020'
  },
  {
    id: 'matai-2020',
    name: 'Matai 2020'
  },
  {
    id: 'kahikatea-2020',
    name: 'Kahikatea 2020'
  },
  {
    id: 'piwakawaka-2020',
    name: 'Piwakawaka 2020'
  },
  {
    id: 'harakeke-2020',
    name: 'Harakeke 2020'
  },
  {
    id: 'kahu-2020',
    name: 'Kahu 2020'
  }
]
