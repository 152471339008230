import React, { useContext } from 'react'

import cohorts from '../../cohorts'
import CohortSelectionContext from './Context'

const CohortSelectionDropdown = () => {
  const {selectedCohort, changeCohort} = useContext(CohortSelectionContext)

  return (
    <select
      aria-label="Select your cohort"
      value={selectedCohort}
      onChange={changeCohort}
      className='cohort-dropdown'>
      <option value=''>Select your cohort</option>
      {cohorts.map(cohort => (
        <option
          key={cohort.id}
          value={cohort.id}>
          {cohort.name}
        </option>
      ))}
    </select>
  )
}

export default CohortSelectionDropdown
